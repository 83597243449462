<section class="separacion">
  <div class="titulo">
    <div class="linea"></div>
    <h2>PRINCIPIOS <span class="resaltar">CORPORATIVOS</span></h2>
  </div>

  <div class="margenesQ">
    <div class="principioCor">
      <div class="tituloPrincipio">
        <div class="imagen">
          <img src="../../../../assets/QuienesSomos/check.svg" alt="Icono-Check">
        </div>
        <h3>{{informacion[0].titulo.toUpperCase()}}</h3>
      </div>

      <div class="contenido">
        <p>{{informacion[0].informacion}}</p>
      </div>
    </div>

    <div class="principioCor">
      <div class="tituloPrincipio">
        <div class="imagen">
          <img src="../../../../assets/QuienesSomos/check.svg" alt="Icono-Check">
        </div>
        <h3>{{informacion[1].titulo.toUpperCase()}}</h3>
      </div>

      <div class="contenido">
        <p>{{informacion[1].informacion}}</p>
      </div>
    </div>

    <div class="principioCor">
      <div class="tituloPrincipio">
        <div class="imagen">
          <img src="../../../../assets/QuienesSomos/check.svg" alt="Icono-Check">
        </div>
        <h3>{{informacion[2].titulo.toUpperCase()}}</h3>
      </div>

      <div class="contenido">
        <p>{{informacion[2].informacion}}</p>
      </div>
    </div>
  </div>

</section>
