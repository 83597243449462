import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StrapiService } from 'src/app/Services/strapi.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  // images = [0, 1, 2, 3].map((n) => `../../../assets/carousel-img/${n}.png`);
  images = [];
  isLoading = true;

  dataCarousel: any;

  constructor( private strapiService: StrapiService ) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    animateOut: 'fadeOut',
    autoplayTimeout: 5000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        dots: false
      },
      400: {
        items: 1,
        dots: false
      },
      740: {
        items: 1,
        dots: false
      },
      940: {
        items: 1,
      }
    }
  };

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    const data = await this.strapiService.getData('data-home');
    this.dataCarousel = data?.attributes.HomeCarousel.data;

    if(this.dataCarousel)
      this.images = this.dataCarousel.map( ({attributes}: any ) => attributes.url );
      this.isLoading = false;
  }

}
