<section class="separacion">
  <div class="titulo">
    <div class="linea"></div>
    <h2>
      <span class="resaltar">NUESTRA</span> HISTORIA
    </h2>
  </div>

  <p class="parrafo-historia" >{{data[0].informacion}}</p>
  <br>
  <p class="parrafo-historia" >{{data[1].informacion}}</p>
  <br>
  <p class="parrafo-historia" >{{data[2].informacion}}</p>

  <section class="margenesQ">
    <div class="mision separacion ">
      <div class="banner-mision">
        <img src="../../../../assets/images-quienesSomos/mision-vision/mision.png" alt="mision">
        <h2 class="position-right">MISIÓN</h2>
        <p class="">{{data[3].mision}}</p>
      </div>
    </div>
    <div class="vision separacion">
      <div class="banner-vision">
        <img src="../../../../assets/images-quienesSomos/mision-vision/vision.png" alt="vision">
        <h2 class="position-left">VISIÓN</h2>
        <p class="">{{data[4].vision}}</p>
      </div>
    </div>
  </section>

</section>
