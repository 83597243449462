import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StrapiService } from 'src/app/Services/strapi.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  titulo = 'Testimonios';
  datos: any = [];

  constructor( private strapiService: StrapiService ) { }
  customOptions: OwlOptions = {
    // loop: true,
    // mouseDrag: true,
    // touchDrag: true,
    // pullDrag: true,
    // dots: true,
    // navSpeed: 700,
    // autoplay: true,
    // autoplayTimeout: 5000,
    dots: false,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: true,
        loop: true
      },
      400: {
        items: 1,
        dots: true,
        nav: false
      },
      740: {
        items: 2,
        margin: 20,
        nav: false
      },
      940: {
        items: 3,
        margin: 30,
        nav: false
      }
    }
  };
  ngOnInit(): void {
    this.cargarDatos();
  }

  async cargarDatos() {
    const data = await this.strapiService.getData('testimonios');

    const temp = [
      {
        nombre: 'Greily Tarazona',
        imagen: './../../../../assets/images-testimonios/GreilyTarazona.png',
        cuerpo: 'Estoy muy agradecida con la Ortodoncista Eliana Claro, porque con su excelente trabajo logró que recuperara la seguridad al momento de sonreír.'
      },
      {
        nombre: 'Jhon Jairo',
        imagen: './../../../../assets/images-testimonios/JhonJairo.png',
        cuerpo: 'Gracios a SALUD & ESTÉTICA clínica odontológica, puedes tener una sonrisa perfecta más fácil de lo que crees.'
      },
      {
        nombre: 'Sara Carrascal',
        imagen: './../../../../assets/images-testimonios/SaraCarrascal.png',
        cuerpo: 'Mi experiencia en la clinica SALUD & ESTÉTICA ha sido muy buena, mis expectativas fueron mayores en cuanto al tratamiento que se ha llevado a cabo, en general me he sentido muy satisfecha.',
      },
    ];

    if(data) {
      data.map( (el: any) => {
        const test = el.attributes;
        const imagen = test.FotoPersona.data.attributes.url;

        if (test.Testimonio.length > 233) {
          this.datos.push(
            {
              imagen: imagen,
              cuerpo: `${test.Testimonio.slice(0, 233)}...`,
              nombre: test.Nombre
            }
          );
        } else {
          const esp = 233 - test.Testimonio.length;
          this.datos.push(
            {
              imagen: imagen,
              cuerpo: test.Testimonio,
              nombre: test.Nombre
            }
          );
        }

      });
    } else {
      temp.map(el => {
        if (el.cuerpo.length > 233) {
          this.datos.push(
            {
              imagen: el.imagen,
              cuerpo: `${el.cuerpo.slice(0, 233)}...`,
              nombre: el.nombre
            }
          );
        } else {
          const esp = 233 - el.cuerpo.length;
          this.datos.push(
            {
              imagen: el.imagen,
              cuerpo: el.cuerpo,
              nombre: el.nombre
            }
          );
        }
      });
    }
  }

}
