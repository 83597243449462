<footer class="footer">
  <div class="infoFooter">

    <div class="col1">
      <div class="logo">
        <img src="./../../../assets/imagenesFooter/logo-footer.svg" alt="logo-footer">
      </div>
      <div class="vss">
        <!-- <a href="./../../../assets/documentos/BALANCE_COMPARATIVO_SyE_2020-2021.pdf" target="_blank"> -->
        <a href="https://drive.google.com/drive/u/0/folders/1VcOVZf19fx7WdCc02yING4DrpbZLC4XP" target="_blank">
          <img src="./../../../assets/imagenesFooter/vigilados-supersalud.svg" alt="imagen vigilados Super-Salud">
        </a>
      </div>
    </div>

    <div class="col2">
      <!-- <h2 class="titleSecundary tituloTratamientos">Tratamientos</h2> -->
      <a [routerLink]="[ '/tratamientos' ]"><h2 class="titleSecundary tituloTratamientos">Tratamientos</h2></a>

      <div class="tratamientos" *ngFor="let tratamiento of tratamientos">
        {{ tratamiento }}
        <div class="linea"></div>
      </div>

    </div>

    <div class="col3">

      <section class="horarios">
        <h2 class="titleSecundary tituloHorarios">Horarios de atención</h2>
        <div class="horarioHora" *ngFor="let horario of horarios">
          <div class="dias">
            {{horario.dia}}
          </div>
          <div class="horas">
            {{horario.hora}}
          </div>
        </div>
      </section>

      <section class="redesSociales">
            <a href="https://www.facebook.com/syeodontologica" target="_blank">
              <fa-icon [icon]="faFacebookSquare"></fa-icon>
            </a>
            <a href="https://www.instagram.com/saludyestetica.co/" target="_blank">
              <fa-icon [icon]="faInstagramSquare"></fa-icon>
            </a>
            <a href="https://wa.me/573153818593" target="_blank">
              <fa-icon [icon]="faWhatsappSquare"></fa-icon>
            </a>
      </section>

      <section class="mediosDePago">
        <img src="./../../../assets/imagenesFooter/tarjetas-credito-logos.svg" alt="medios de pago">
      </section>

    </div>
  </div>

  <div class="crediitos">
    <span class="superior">
      © {{date}} CLINICAODONTOLOGICASALUDYESTETICA.COM
    </span>
    <br>
    <span class="inferior">
      Desarrollado por DevSoul
    </span>
  </div>
</footer>
