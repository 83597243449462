import { Component, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/Services/strapi.service';

@Component({
  selector: 'app-profesionales',
  templateUrl: './profesionales.component.html',
  styleUrls: ['./profesionales.component.scss']
})
export class ProfesionalesComponent implements OnInit {

  // img = '../../../assets/profesionales/banner/banner.png';
  img = '';

  constructor( private strapiService: StrapiService ) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    const data = await this.strapiService.getData('data-profesionales');

    if(data && data.attributes.Banner.data)
      this.img = data.attributes.Banner.data.attributes.url;
  }

}
