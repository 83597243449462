<section class="separacion">
  <!-- Parte Mobile -->
  <div class="titulo">
    <div class="linea"></div>
    <h2>
      <span class="resaltar">ESPECIALIDADES</span>
    </h2>
  </div>
  <div class="mobile margenesQ" *ngFor="let item of data; let i = index">
    <button class="accordion" (click)="toggleAccordian($event, i)">
      {{ item.parentName.toUpperCase() }}
    </button>
    <div class="panel" hide="!item.isActive">
      <div class="seccion1">
        <h3 class="resaltar tamaño" *ngIf="item.titulo1">{{ item.titulo1 }}</h3>
        <p>{{ item.content1 }}</p>
        <h3 class="resaltar tamaño" *ngIf="item.titulo2">{{ item.titulo2 }}</h3>
        <p *ngIf="item.content2">{{ item.content2 }}</p>
      </div>
      <div class="seccion2" *ngIf="item.check">
        <div *ngFor="let desc of item.check">
          <div class="check">
            <img class="a" src="../../../../assets/QuienesSomos/check.svg" alt="img" />
            <p>{{ desc.contenido }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- parte Web -->
  <div class="izquierda">
    <div class="tituloIzquierda">
      <h2><span class="resaltar">ESPECIALIDADES</span></h2>
      <div class="linea"></div>
    </div>
  </div>

  <div class="web">
    <div class="izquierda">
      <div class="menu" *ngFor="let item of data">
        <div
          class="item"
          [class.activo]="item.select"
          (click)="viewInfo(item)"
          [@changeState]="item.toState"
        >
          <div class="linea" *ngIf="item.select"></div>
          <span>{{ item.parentName }}</span>
        </div>
      </div>
    </div>

    <div class="derecha">
      <div class="derecha">
        <div class="imagen animate__animated animate__fadeIn animate__delay-0.8s" [@changeState]="toStateContent1">
          <img src={{imagen}} alt={{nombre}}>
        </div>

        <div class="cuerpo animate__animated animate__fadeIn animate__delay-0.8s" [@changeState]="toStateContent1">
          <div class="seccion1">
              <h3 class="resaltar" *ngIf="titulo1">{{titulo1}}</h3>
              <p>{{content1}}</p>
              <h3 class="resaltar" *ngIf="titulo2">{{titulo2}}</h3>
              <p *ngIf="content2">{{content2}}</p>
          </div>
          <div class="seccion2" *ngIf="contenido">
              <div *ngFor="let desc of contenido">
                  <div class="check">
                      <img src="../../../../assets/QuienesSomos/check.svg" alt="Icono-Check" />
                      <p>{{desc.contenido}}</p>
                  </div>
              </div>
          </div>
        </div>
      </div>

      <div class="imagen animate__animated animate__fadeIn animate__delay-0.8s" [@changeState]="toStateContent2">
        <img src={{imagen}} alt={{nombre}}>
      </div>

      <div class="cuerpo animate__animated animate__fadeIn animate__delay-0.8s" [@changeState]="toStateContent2">
        <div class="seccion1">
            <h3 class="resaltar" *ngIf="titulo1">{{titulo1}}</h3>
            <p>{{content1}}</p>
            <h3 class="resaltar" *ngIf="titulo2">{{titulo2}}</h3>
            <p *ngIf="content2">{{content2}}</p>
        </div>
        <div class="seccion2" *ngIf="contenido">
            <div *ngFor="let desc of contenido">
                <div class="check">
                    <img src="../../../../assets/QuienesSomos/check.svg" alt="Icono-Check" />
                    <p>{{desc.contenido}}</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
