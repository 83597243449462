import { Component, OnInit } from '@angular/core';
import { faFacebookSquare, faInstagramSquare, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  faFacebookSquare = faFacebookSquare;
  faInstagramSquare = faInstagramSquare;
  faWhatsappSquare = faWhatsappSquare;

  tratamientos = ['Ortodoncia', 'Odontología General', 'Diseño de Sonrisa', 'Periodoncia', 'Endodoncia'];

  horarios = [
    {
      dia: 'Lunes - Viernes',
      hora: '8:00 - 18:00'
    },
    {
      dia: 'Sábado',
      hora: '8:00 - 12:00'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }
  get date(){
    const fecha = new Date();
    return fecha.getFullYear();
  }



}
