import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-valores-corporativos',
  templateUrl: './valores-corporativos.component.html',
  styleUrls: ['./valores-corporativos.component.scss']
})
export class ValoresCorporativosComponent implements OnInit {

  informacion: any = [];

  constructor() { }

  ngOnInit(): void {
    this.cargarInformacion();
  }

  cargarInformacion() {
    this.informacion.push(
      {}
    );
  }

}
