<app-carousel class="separacion"></app-carousel>
<div class="separacion margenesQ">
    <app-cita></app-cita>
</div>
<div class="separacion">
    <app-bienvenidos></app-bienvenidos>
</div>
<div class="separacion margenesQ">
    <app-time-line></app-time-line>
</div>
<div class="separacion margenesQ">
    <app-cards></app-cards>
</div>
