import { Component, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/Services/strapi.service';

@Component({
  selector: 'app-nosotros',
  templateUrl: './nosotros.component.html',
  styleUrls: ['./nosotros.component.scss']
})
export class NosotrosComponent implements OnInit {
  // img ='../../../../assets/images-quienesSomos/banner/0.png';
  img ='';

  constructor( private strapiService: StrapiService ) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    const data = await this.strapiService.getData('data-quienes-somos');

    if(data && data.attributes.Banner.data)
      this.img = data.attributes.Banner.data.attributes.url;
  }

}
