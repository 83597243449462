import { Component, OnInit } from '@angular/core';
import { StrapiService } from 'src/app/Services/strapi.service';

@Component({
  selector: 'app-tratamientos',
  templateUrl: './tratamientos.component.html',
  styleUrls: ['./tratamientos.component.scss']
})
export class TratamientosComponent implements OnInit {
  // img ='../../../assets/tratamientos/banner/banner.png';
  img ='';

  constructor( private strapiService: StrapiService ) { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    const data = await this.strapiService.getData('data-tratamiento');

    if(data && data.attributes.Banner.data)
      this.img = data.attributes.Banner.data.attributes.url;
  }

}
