<section class = "body">
    <h2 class="titlePrimary separacionTituloContenido">{{titulo.toUpperCase()}}</h2>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let opt of datos">
            <div class="texto">
                <div class="img-responsive">
                    <img [src]="opt.imagen"
                        class="card-img-top" alt="Imagen {{opt.nombre}}">
                </div>
                <div class="card-body cuerpo">
                  <span class="nombreCard">{{opt.nombre}}</span>
                    <p class="parrafo" >{{opt.cuerpo}}</p>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</section>
