<section class="separacion">
    <div class="titulo">
      <div class="linea"></div>
      <h2><span class="resaltar">PROFESIONALES</span></h2>
    </div>
    <div class = "margenesQ cuerpo">
        <p>En <span>SALUD & ESTÉTICA</span> clínica odontológica, contamos con un excelente equipo de profesionales que pone en práctica los más exigentes controles de ética y calidad, acompañados de toda la tecnología disponible en nuestras instalaciones, para brindarle una atención oportuna, segura y confiable, garantizando bienestar y satisfacción en todos los procedimientos dentales.
            <br><br>Lo invitamos a conocer nuestro equipo de trabajo.</p>
    </div>
</section>
