import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import swal from 'sweetalert';
// import 'src/assets/smtp';

declare let Email: any;

@Injectable({
  providedIn: 'root'
})
export class FuncionesGeneralesService {

  constructor() { }

  env = environment;
  validarEmail(valor: FormControl) {

    const exprecionEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

    if (exprecionEmail.test(valor.value)!== true) {
      return { validarEmail: true };
    }
    return null;
  }

  mensaje(title: string, body: string, type: string) {
    return swal(title, body, type);
  }

}
