<section class="separacion">
  <!-- Parte Mobile titulo -->
  <div class="tituloValores">
    <div class="imagenValoresTitulo">
      <img src="../../../assets/QuienesSomos/ValoresMobile2.png" alt="QuienesSomos">
    </div>
    <div class="textoTitulo">
      <h2>RHOICS / <span class="resaltar">VALORES CORPORATIVOS</span></h2>
    </div>
  </div>

  <!-- Parte Web titulo -->
  <div class="izquierda">
    <div class="tituloIzquierda">
      <h2>VALORES <span class="resaltar">CORPORATIVOS</span></h2>
      <div class="linea"></div>
    </div>
  </div>

  <div class="cuerpoTexto margenesQ">
    <div class="imagenTexto">
      <img #imagen src="../../../assets/QuienesSomos/rhoics.png" alt="RHOICS">
    </div>
    <div class="textoValores">
      <p>Los valores organizacionales “RHOICS” son el pilar de nuestra organización. Juntos, representan las acciones y actividades de todos los colaboradores de SALUD & ESTÉTICA quienes con un gran compromiso los ponen en práctica día a día, para contribuir a nuestra cultura, imagen y clima laboral.</p>
    </div>
  </div>


  <div class="imagenCuerpo margenesQ">
    <img #imagen src="../../../assets/QuienesSomos/RHOICS1.png" alt="RHOICS">
  </div>
</section>
