
        <div class="fachada">
            <div class='imagenFachada'>
                <div class="text-center contenido">
                    <p class = "titleSecundary tituloBanner">{{ contenido.titulo }}</p>
                    <p class = "lema">{{ contenido.lema }}</p>
                    <p class="parrafo">{{ contenido.presentacion }}</p>
                    <p class="gracias">{{ contenido.agradecimiento }}</p>
                </div>
            </div>
        </div>
