<h2 class="titlePrimary separacionTituloContenido">{{titulo.toUpperCase()}}</h2>
<p>{{parrafo}}</p>
<mzd-timeline>
    <mzd-timeline-content *ngFor="let item of timeData;let i = index;">
      <button class="accordion" (click)="toggleAccordian($event, i)"> 
        {{item.parentName}} 
      </button>
      <div class="panel" *ngFor="let child of item.childProperties"  hide="!item.isActive">
        <hr>
        <p>{{child.content}}</p>
      </div>
    </mzd-timeline-content>
</mzd-timeline>