import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuncionesGeneralesService } from '../../../../Services/funciones-generales.service';
import { EmailService } from '../../../../Services/email.service';
import { NotifyService } from '../../../../Services/notify.service';
// import '../../../assets/smtp';


@Component({
  selector: 'app-form-contacto',
  templateUrl: './form-contacto.component.html',
  styleUrls: ['./form-contacto.component.scss']
})
export class FormContactoComponent implements OnInit {

  loading = false;

  constructor(private fb: FormBuilder, private fg: FuncionesGeneralesService, private es: EmailService, private ns:NotifyService) { }
  Form: FormGroup = this.fb.group({});

  ngOnInit(): void {
    this.formulario();
  }


  formulario() {
    this.Form = this.fb.group({
      nombreApellido: ['', [Validators.required, Validators.minLength(8)]],
      asunto: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, this.fg.validarEmail]],
      mensaje: ['', [Validators.required, Validators.minLength(15)]],
      check: ['', [Validators.required]]
    });
  }
  destructureValueForm() {
    const { asunto, check, email, mensaje, nombreApellido } = this.Form.value;
    return { asunto, check, email, mensaje, nombreApellido };
  }

  validarCampo(campo: string) {

    if (this.Form.controls[campo].touched && this.Form.controls[campo].invalid) {
      return true;
    } else {
      return false;
    }
  }
  enviar() {
    if (this.Form.valid) {
      this.loading = true;
      const elements = this.destructureValueForm();
      const body = `<b>Nombres y Apellidos:</b> ${elements.nombreApellido}<br><b>Aunto:</b> ${elements.asunto}<br><b>Correo:</b> ${elements.email}<br><b>Mensaje:</b> ${elements.mensaje}`;

      const data = new FormData();
      data.append('asunto', elements.asunto);
      data.append('body', body);
      this.es.sendEmail(data).subscribe(res => {
        if (this.fg.validarEmail(res.message.accepted.toString())) {
          this.fg.mensaje('Exito!', 'Se envio mensaje con exito.', 'success');
          this.loading = false;
          this.Form.reset();
        }
      }, (err => {
        this.fg.mensaje('Uups, Algo salio mal.', 'Estamos tarabajando para resolver el problema', 'error');
        this.loading = false;
        this.ns.nEmail(err);
      }));
    } else {
      this.fg.mensaje('Formilario no valido', 'El formulario no fue completado de manera correcta', 'info');
    }
  }
}




