import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bienvenidos',
  templateUrl: './bienvenidos.component.html',
  styleUrls: ['./bienvenidos.component.scss']
})
export class BienvenidosComponent implements OnInit {

  contenido = {
    titulo : '¡BIENVENIDOS!',
    lema : 'Creamos sonrisas hermosas',
    presentacion: 'Somos una clínica integral de odontología que nació bajo la iniciativa de 2 odontólogos, al querer ofrecer los mejores servicios de odontología general y especializada en la ciudad de Ocaña . Actualmente, contamos con un equipo de profesionales altamente calificados y toda la tecnología disponible, para garantizarle una experiencia única en nuestras instalaciones y un servicio de calidad.',
    agradecimiento: '¡Gracias por visitarnos!'

  };
  constructor() { }

  ngOnInit(): void {
  }

}
