<ul class="nav justify-content-center navP">
    <li class="nav-item">
        <a [routerLink]="[ 'formContacto' ]" routerLinkActive="activeP">Contacto</a>
    </li>
    <li class="nav-item">
        <a [routerLink]="[ 'formTrabaja' ]" routerLinkActive="activeP">Trabaja con nosotros</a>
    </li>

</ul>
<div class="formularios">
    <router-outlet></router-outlet>
</div>
