<div class="formulario animate__animated animate__fadeIn animate__delay-0.3s" [formGroup]="Form">
    <div class="input-group mb-3">
        <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
        <span class="input-group-text">
            <div class="icono">
                <img src="../../../assets/iconos/icono_nombre.svg" alt="Icono-Correo">
            </div>
        </span>
        <input type="text" class="form-control" [class.is-invalid]="validarCampo('nombreApellido')"
            placeholder="Nombres y apellidos" formControlName="nombreApellido">
    </div>
    <div class="error" *ngIf="validarCampo('nombreApellido')">
        <span class="text-danger">El campo debe tener minimo 8 caracteres alfabeticos</span>
    </div>

    <div class="input-group mb-3">
        <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
        <span class="input-group-text">
            <div class="icono">
                <img src="../../../assets/iconos/icono_documento-identidad.svg" alt="icono_documento-identidad">
            </div>
        </span>
        <input type="number" class="form-control" [class.is-invalid]="validarCampo('identificacion')"
            placeholder="Número de identificación" formControlName="identificacion">
    </div>
    <div class="error" *ngIf="validarCampo('identificacion')">
        <span class="text-danger">El campo debe tener minimo 8 caracteres nuemericos</span>
    </div>

    <div class="input-group mb-3">
        <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
        <span class="input-group-text">
            <div class="icono">
                <img src="../../../assets/iconos/icono_arroba.svg" alt="icono_arroba">
            </div>
        </span>
        <input type="text" class="form-control" placeholder="Correo" [class.is-invalid]="validarCampo('email')"
            formControlName="email">
    </div>
    <div class="error" *ngIf="validarCampo('email')">
        <span class="text-danger">Correo invalido Ej: correo@gmail.com</span>
    </div>

    <div class="input-group mb-3">
        <input type="file" #filePdf accept=".pdf" class="form-control" formControlName="adjunt">
    </div>
    <div class="error" *ngIf="validarCampo('adjunt')">
        <span class="text-danger">Por favor selecciona un archivo</span>
    </div>

    <div class="input-group mb-3">
        <textarea class="form-control mensaje" [class.is-invalid]="validarCampo('mensaje')"
            placeholder="¿Por que desea trabajar con nosotros?" formControlName="mensaje"></textarea>
    </div>
    <div class="error" *ngIf="validarCampo('mensaje')">
        <span class="text-danger">El campo debe tener minimo 15 caracteres alfabeticos</span>
    </div>

    <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="flexCheckDefault" formControlName="check">
        <label class="form-check-label check" for="flexCheckDefault">
            Acepto <a href="https://www.termsfeed.com/live/1b8e887f-3829-49a0-897f-716789f1025f" target="_blank"
                rel="noopener noreferrer">políticas y Privacidad.</a>
        </label>
    </div>
</div>
<div class="boton">

    <button type="button" class="btn btn-SE" (click)="enviar()"
        [ngClass]="{'disabled': Form.invalid || !Form.controls['check'].value || loading}">
        <div *ngIf="!loading">Enviar</div>
        <div class="spinner-border" role="status" *ngIf="loading">
            <span class="visually-hidden">Loading...</span>
        </div>
    </button>
</div>