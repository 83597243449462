import { Component, OnInit } from '@angular/core';
import {
  faFacebookSquare,
  faInstagramSquare,
  faWhatsappSquare,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  scroll = false;

  mostrarlinea = false;
  menuActivo = false;
  infoActivo = false;

  faFacebookSquare = faFacebookSquare;
  faInstagramSquare = faInstagramSquare;
  faWhatsappSquare = faWhatsappSquare;

  constructor() {}

  ngOnInit() {}

  cambiarEstado(id: number) {
    if (id === 1) {
      this.menuActivo = !this.menuActivo;
    }
    if (id === 2) {
      this.infoActivo = !this.infoActivo;
    }

    if (this.menuActivo && this.infoActivo) {
      this.mostrarlinea = true;
    } else if (id === 1) {
      this.mostrarlinea = false;
    } else {
      this.mostrarlinea = false;
      setTimeout(() => {}, 240);
    }
  }
}
