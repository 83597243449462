import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principios-corporativos',
  templateUrl: './principios-corporativos.component.html',
  styleUrls: ['./principios-corporativos.component.scss']
})
export class PrincipiosCorporativosComponent implements OnInit {

  informacion: any = [];

  constructor() { }

  ngOnInit(): void {
    this.cargarInformacion();
  }

  cargarInformacion() {
    this.informacion.push(
      {
        titulo: 'Ética profesional',
        informacion: 'Servir a la sociedad con el mayor respeto, para brindar soluciones odontológicas de manera responsable, eficientes y de alta calidad.'
      },
      {
        titulo: 'Trabajo en equipo',
        informacion: 'Trabajamos en equipo como profesionales, nos brindamos apoyo y soporte para garantizar el éxito en cada tratamiento realizado y así lograr la satisfacción de nuestros clientes.'
      },
      {
        titulo: 'Calidad y excelencia',
        informacion: 'Contamos con profesionales egresados de las mejores universidades en cada área ofrecida en nuestra clínica, con capacitaciones constantes para ofrecer permanentemente calidad e innovación.'
      }
    );
  }

}
