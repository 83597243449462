<section class = "bodyCabezera">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let item of contenido" >
            <div class= "img-responsive cursor" (click)="abrirModal(modalPersonal, item)">
                <img [src]="item.img" alt="Imagen perfil {{item.nombre}}">
            </div>
            <p class="nombre">{{item.nombre}}</p>
        </ng-template>
    </owl-carousel-o>
</section>

<!-- Modal -->
<ng-template #modalPersonal let-c="close" let-d="dismiss">
  <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body body row">
    <div class="cuerpoModal">

      <div class="izquierda">
        <div class="fotoModal">
          <img src="{{fotoPersonal}}" />
        </div>
        <div class="encabezado">
          <h3>{{nombrePersonal}}</h3>
        </div>
      </div>

      <div class="derecha">
        <div class="contenido">
          <div class="titulos">
            <h3>{{tituloPersonal}}</h3>
            <h4>{{universidadTituloPersonal}}</h4>
            <h3>{{especializacionPersonal}}</h3>
            <h4>{{universidadEspPersonal}}</h4>
          </div>

          <div class="diplomados" *ngIf="diplomadosPersonal">
            <h3>Diplomados:</h3>
            <p [innerHTML]="diplomadosPersonal"></p>
          </div>
        </div>

        <div class="footerModal">
          <img src="../../../../assets/imagenesFooter/logo-footer.svg" />
        </div>
      </div>

    </div>
  </div>
</ng-template>
