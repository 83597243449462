import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SkeletonModule } from "primeng/skeleton";

import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MenuComponent } from './Components/menu/menu.component';
import { MenuDirective } from './Components/menu/menu.directive';
import { FooterComponent } from './Components/footer/footer.component';

import { HomeComponent } from './Components/home/home.component';
import { CarouselComponent } from './Components/home/carousel/carousel.component';
import { BienvenidosComponent } from './Components/home/bienvenidos/bienvenidos.component';
import { CitaComponent } from './Components/home/cita/cita.component';
import { CardsComponent } from './Components/home/cards/cards.component';
import { MzdTimelineModule } from 'ngx-rend-timeline';


import { TratamientosComponent } from './Components/tratamientos/tratamientos.component';
import { NosotrosComponent } from './Components/quienesSomos/nosotros.component';
import { ProfesionalesComponent } from './Components/profesionales/profesionales.component';
import { ContactanosComponent } from './Components/contactanos/contactanos.component';
import { MapaComponent } from './Components/contactanos/mapa/mapa.component';
import { FormulariosComponent } from './Components/contactanos/formularios/formularios.component';
import { FormContactoComponent } from './Components/contactanos/formularios/form-contacto/form-contacto.component';
import { FormTrabajaComponent } from './Components/contactanos/formularios/form-trabaja/form-trabaja.component';
import { MisionVisionComponent } from './Components/quienesSomos/mision-vision/mision-vision.component';
import { ValoresCorporativosComponent } from './Components/quienesSomos/valores-corporativos/valores-corporativos.component';
import { PrincipiosCorporativosComponent } from './Components/quienesSomos/principios-corporativos/principios-corporativos.component';
import { IntroProfesionalesComponent } from './Components/profesionales/intro-profesionales/intro-profesionales.component';
import { SliderProfesionalesComponent } from './Components/profesionales/slider-profesionales/slider-profesionales.component';
import { TimeLineComponent } from './Components/home/time-line/time-line.component';
import { TAccordionComponent } from './Components/tratamientos/t-accordion/t-accordion.component';
import { BannersComponent } from './Components/shared/banners/banners.component';




@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    CarouselComponent,
    CitaComponent,
    FooterComponent,
    CardsComponent,
    TratamientosComponent,
    NosotrosComponent,
    ProfesionalesComponent,
    ContactanosComponent,
    BienvenidosComponent,
    MapaComponent,
    FormulariosComponent,
    FormContactoComponent,
    FormTrabajaComponent,
    MisionVisionComponent,
    ValoresCorporativosComponent,
    PrincipiosCorporativosComponent,
    IntroProfesionalesComponent,
    SliderProfesionalesComponent,
    TimeLineComponent,
    TAccordionComponent,
    BannersComponent,
    MenuDirective,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    CarouselModule,
    SkeletonModule,
    MzdTimelineModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
