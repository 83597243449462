import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class TimeLineComponent implements OnInit {
  titulo= 'NUESTRA TRAYECTORIA';
  parrafo = 'Con el paso del tiempo SALUD & ESTÉTICA ha logrado consolidarse como una empresa fundamentada en la ética y los valores, formada por una gran familia. A nivel regional, es reconocida como una excelente empresa, que ha perdurado en el tiempo y ha mantenido una impecable imagen, calidad e idoneidad.';
  timeData: any =[];

  constructor() { }

  ngOnInit(): void {
    this.cargarInfo();
  }

  cargarInfo(){
    this.timeData.push(
      {
        parentName: '1992',
        childProperties:
          [
            { content: 'Los odontólogos Carlos Alberto Cáceres González y Eliana Mercedes Claro Álvarez, toman la iniciativa de crear empresa en el área de la odontología, con el ánimo de servir a la población ocañera  y aplicar los conocimientos adquiridos durante su preparación universitaria; así, nace el Centro Odontológico Integral, consultorio de profesionales independientes donde se brindarán todos los servicios de odontología general.' }
          ]
      },
      {
        parentName: '2003',
        childProperties:
          [
            { content: 'Tras la demanda de la población ocañera, específicamente en diferentes especialidades de la odontología, surge la necesidad de ampliar los servicios prestados; situación que motiva a los odontólogos para especializarse en ortodoncia, ortopedia maxilar, periodoncia y osteointegración, conformando un equipo multidisciplinario para brindar los nuevos servicios de ortodoncia, endodoncia, periodoncia, cirugía y rehabilitación oral.'}
          ]
      },
      {
        parentName: '2008',
        childProperties:
          [
            { content: 'Surge Salud & Estética Clínica Odontológica, como una Institución prestadora de servicios de salud legalmente constituida, con todos los requerimientos necesarios para los servicios de odontología general y especializada; con un diseño moderno y diferente a las demás instituciones de salud.' }
          ]
      },
      {
        parentName: '2012',
        childProperties:
          [
            { content: 'Con el aumento de pacientes, el flujo de atención  y servicio al cliente fue creciendo, generando la necesidad de ampliar las instalaciones para seguir prestando un mejor servicio. Por lo anterior, se decide construir dos consultorios más (para un total de seis consultorios), otra sala de espera, una oficina de gerencia, un almacén para los insumos odontológicos  y dos baños más.' }
          ]
      },
      {
        parentName: '2019',
        childProperties:
          [
            { content: 'Se rediseña la imagen corporativa de la clínica, incursionando en las nuevas redes sociales y plataformas digitales, para lograr una mayor comunicación e interacción con los pacientes y la comunidad en general.'}
          ]
      },
      {
        parentName: 'Actualidad',
        childProperties:
          [
            { content: 'Actualmente, esta empresa prestadora de servicios odontológicos,  continúa trabajando arduamente para garantizar la mejor experiencia y servicio al cliente en todas sus especialidades, comprometida siempre con la innovación, el mejoramiento y la responsabilidad social empresarial.'}
          ]
      },
    );
  }

  toggleAccordian(event: { target: any; }, index: string | number) {
    const  element = event.target;
    element.classList.toggle('active');
    if(this.timeData[index].isActive) {
      this.timeData[index].isActive = false;
    } else {
      this.timeData[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
