import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Components/home/home.component';
import { TratamientosComponent } from './Components/tratamientos/tratamientos.component';
import { NosotrosComponent } from './Components/quienesSomos/nosotros.component';
import { ProfesionalesComponent } from './Components/profesionales/profesionales.component';
import { ContactanosComponent } from './Components/contactanos/contactanos.component';
import { FormContactoComponent } from './Components/contactanos/formularios/form-contacto/form-contacto.component';
import { FormTrabajaComponent } from './Components/contactanos/formularios/form-trabaja/form-trabaja.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'tratamientos', component: TratamientosComponent },
  { path: 'quienesSomos', component: NosotrosComponent },
  { path: 'profesionales', component: ProfesionalesComponent },
  {
    path: 'contactanos', component: ContactanosComponent, children: [
      { path: 'formContacto', component: FormContactoComponent },
      { path: 'formTrabaja', component: FormTrabajaComponent },
      { path: '**', pathMatch: 'full', redirectTo: 'formContacto' }
    ]},
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
