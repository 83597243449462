import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuncionesGeneralesService } from '../../../../Services/funciones-generales.service';
import { EmailService } from '../../../../Services/email.service';
// import '../../../assets/smtp';

@Component({
  selector: 'app-form-trabaja',
  templateUrl: './form-trabaja.component.html',
  styleUrls: ['./form-trabaja.component.scss']
})
export class FormTrabajaComponent implements OnInit {

  @ViewChild('filePdf', {
    read: ElementRef
  }) filePdf!: ElementRef<any>;

  loading = false;

  constructor(private fb: FormBuilder, private fg: FuncionesGeneralesService, private es: EmailService) { }
  Form: FormGroup = this.fb.group({});

  ngOnInit(): void {
    this.formulario();
  }

  formulario() {
    this.Form = this.fb.group({
      nombreApellido: ['', [Validators.required, Validators.minLength(8)]],
      identificacion: ['', [Validators.required, Validators.minLength(8)]],
      email: ['', [Validators.required, this.fg.validarEmail]],
      adjunt: ['', [Validators.required]],
      mensaje: ['', [Validators.required, Validators.minLength(15)]],
      check: ['', [Validators.required]]
    });
  }
  destructureValueForm() {
    const { adjunt, identificacion, check, email, mensaje, nombreApellido } = this.Form.value;
    return { adjunt, identificacion, check, email, mensaje, nombreApellido };
  }
  validarCampo(campo: string) {
    if (this.Form.controls[campo].touched && this.Form.controls[campo].invalid && this.Form.controls[campo].errors) {
      return true;
    } else {
      return false;
    }
  }

  enviar() {
    if (this.Form.valid) {
      this.loading = true;
      const file = this.filePdf.nativeElement.files;
      const elements = this.destructureValueForm();
      const body = `<b>Nombres y Apellidos:</b> ${elements.nombreApellido}<br><b>Aunto:</b> ${elements.identificacion}<br><b>Correo:</b> ${elements.email}<br><b>Dirección:</b> ${elements.mensaje}`;
      const data = new FormData();
      data.append('archivo', file[0]);
      data.append('asunto', 'Hoja de vida');
      data.append('body', body);

      this.es.sendEmail(data).subscribe(res => {
        if (this.fg.validarEmail(res.message.accepted.toString())) {
          this.fg.mensaje('Exito!', 'Se envio mensaje con exito.', 'success');
          this.loading = false;
          this.Form.reset();
        }
      },(err => {
        this.fg.mensaje('Uups, Algo salio mal.', 'Estamos tarabajando para resolver el problema', 'error');
        this.loading = false;
      }));
    } else {
      this.fg.mensaje('Formilario no valido', 'El formulario no fue completado de manera correcta', 'info');
    }
  }

}
