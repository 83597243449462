<section>
  <div *ngIf="isLoading">
    <p-skeleton height="50rem" styleClass="p-mb-2"></p-skeleton>
  </div>
  <owl-carousel-o *ngIf="!isLoading" [options]="customOptions" id='carousel-custom-dots'>
      <ng-template carouselSlide *ngFor="let item of images">
            <img [src]="item" alt="Imagen carrusel">
      </ng-template>
  </owl-carousel-o>
</section>
