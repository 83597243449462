<div class="contenedor">
  <div class="texto">
    <img src="../../../../assets/iconos/icono_banner1.png" alt="Icono-banner" />
    <p>Agenda una valoración <strong> AHORA</strong></p>
  </div>
  <button
    ngbButtonLabel
    type="button"
    class="btn botonP"
    (click)="modal(modalVentana)"
  >
    Click aquí
  </button>
</div>

<!-- Modal -->
<ng-template #modalVentana let-c="close" let-d="dismiss">
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="d('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body body row">
    <div class="derecha col">
      <div class="imagen-logo">
        <img
          src="../../../assets/logo/salud-y-estetica_logo-vertical.svg"
          alt="Logo"
        />
      </div>
      <form [formGroup]="Form">
        <div class="input-group mb-3">
          <span class="input-group-text">
            <div class="icono">
              <img
                src="../../../assets/iconos/icono_nombre.svg"
                alt="Icono-Correo"
              />
            </div>
          </span>
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="valida('nombre')"
            placeholder="Nombre y apellido"
            aria-describedby="basic-addon1"
            formControlName="nombre"
          />
        </div>
        <div class="error" *ngIf="valida('nombre')">
          <span class="text-danger">Campo obligatorio</span>
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text">
            <div class="icono">
              <img
                src="../../../assets/iconos/icono_telefono.svg"
                alt="Icono-Correo"
              />
            </div>
          </span>
          <input
            type="number"
            class="form-control"
            [class.is-invalid]="valida('telefono') || errorNumero"
            placeholder="Teléfono"
            aria-describedby="basic-addon1"
            formControlName="telefono"
            (keyup)="validaNumero()"
          />
        </div>
        <div class="error" *ngIf="valida('telefono')">
          <span class="text-danger">Campo obligatorio</span>
        </div>
        <div class="error" *ngIf="errorNumero">
          <span class="text-danger"> Mínimo 5 números.</span>
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text">
            <div class="icono">
              <img
                src="../../../assets/iconos/icono_correo.svg"
                alt="Icono-Correo"
              />
            </div>
          </span>
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="valida('email')"
            placeholder="Correo"
            aria-describedby="basic-addon1"
            formControlName="email"
          />
        </div>
        <div class="error" *ngIf="valida('email')">
          <span class="text-danger">Campo obligatorio</span>
        </div>
        <div class="error" *ngIf="validarEmail()">
          <span class="text-danger">El formato del correo no es valido</span>
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text">
            <div class="icono">
              <img
                src="../../../assets/iconos/icono_direccion.svg"
                alt="Icono-Correo"
              />
            </div>
          </span>
          <input
            type="text"
            class="form-control"
            [class.is-invalid]="valida('direccion')"
            placeholder="Dirección"
            aria-describedby="basic-addon1"
            formControlName="direccion"
          />
        </div>
        <div class="error" *ngIf="valida('direccion')">
          <span class="text-danger">Campo obligatorio</span>
        </div>

        <div class="errorG" *ngIf="error">
          <span class="text-danger">Error en el formulario</span>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            (click)="validaCheck()"
            formControlName="check"
          />
          <label class="form-check-label check" for="flexCheckDefault">
            Acepto
            <a
              href="https://www.termsfeed.com/live/1b8e887f-3829-49a0-897f-716789f1025f"
              target="_blank"
              rel="noopener noreferrer"
              >Políticas y Privacidad.</a
            >
          </label>
        </div>
      </form>
      <div class="footer">
        <button
          type="button"
          class="btn botonEnviar"
          [disabled]="
            Form.invalid || errorNumero || !checkSelect || errorEmail || loading
          "
          (click)="enviar()"
        >
          <div class="spinner-border" role="status" *ngIf="loading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span *ngIf="!loading">Enviar</span>
        </button>
      </div>
    </div>
    <div class="izquierda row col">
      <div class="seccionInfo" *ngFor="let opt of informacion">
        <div>
          <div class="iconoInfo">
            <img [src]="opt.icono" alt="Icono-Correo" />
            <span class="tituloInfo">{{ opt.titulo }}</span>
          </div>
          <p>{{ opt.informacion }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
