import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mision-vision',
  templateUrl: './mision-vision.component.html',
  styleUrls: ['./mision-vision.component.scss']
})
export class MisionVisionComponent implements OnInit {

  data: any = [];

  constructor() { }

  ngOnInit(): void {
    this.cargardata();
  }

  cargardata() {
    this.data.push(
      {
        informacion: 'Somos una clínica odontológica dedicada a brindar servicios de salud oral, conformada desde el año 2008 por un equipo de profesionales y colaboradores idóneos que cuentan con una excelente infraestructura, equipos de última tecnología y ayudas diagnósticas, para garantizar un servicio de alta calidad.'
      },
      {
        informacion: 'Nacimos de la iniciativa de odontólogos que vieron la necesidad de integrar los servicios de odontología general y especializada en un mismo lugar, decidiendo ofrecer las mejores opciones en tratamientos con excelentes planes de financiación.'
      }, {
      informacion: 'Nos encanta superar las expectativas en cada proceso para que nuestros clientes deslumbren con su sonrisa.'
    },
      {
        mision: 'Prestar servicios de odontología general y especializada de alta calidad y con excelente atención a todos nuestros pacientes, fundamentados en el trabajo en equipo, principios, valores y sentido humano. Contando con personal altamente calificado, idóneo y comprometido, logrando así, la satisfacción y seguridad en todos los procedimientos.'
      },
      {
        vision: 'Ser reconocidos durante los próximos 5 años como una de las mejores clínicas odontológicas de la región y el país, ofreciendo un servicio seguro y humanizado con responsabilidad social y excelente calidad; soportado en la educación continua y permanente de nuestros profesionales.'
      }
    );
  }

}
