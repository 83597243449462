import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }
  url = 'http://localhost:3000';
  endpoint = '/api/upload';

  sendEmail(data: any) {
    // return this.http.post<any>(environment.URL + this.endpoint, data);
    return this.http.post<any>(environment.URL + this.endpoint, data).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
