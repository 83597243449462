import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appScroll]',
})
export class MenuDirective {
  constructor() {
  }

  @HostBinding('class.scroll') newNav: boolean | undefined = false;
  @HostListener('window:scroll')
  onScroll() {
    if (window.scrollY >= 160) {
      this.newNav = true;
    } else {
      this.newNav = false;
    }
  }
}
