import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FuncionesGeneralesService } from '../../../Services/funciones-generales.service';
import { EmailService } from '../../../Services/email.service';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss']
})
export class CitaComponent implements OnInit {
  public Form: FormGroup = this.formBuilder.group({});
  error = false;
  loading = false;
  nombre = 'nombre';
  telefono = 'telefono';
  email = 'email';
  direccion = 'direccion';
  errorNumero = false;
  errorEmail = false;
  checkSelect = false;
  informacion: any = [];

  constructor(private formBuilder: FormBuilder,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private fg: FuncionesGeneralesService,
    private es: EmailService) {
    // config.backdrop = 'static';
    config.keyboard = true;
  }

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15)]],
      email: ['', [Validators.required, this.fg.validarEmail]],
      direccion: ['', [Validators.required]],
      check: ['', [Validators.required]]
    });
    this.cargarInformacion();
  }

  modal(modalVentana: any) {
    this.limpiar();
    this.modalService.open(modalVentana, { centered: true, size: 'xl' });
  }

  async enviar() {
    this.loading = true;
    if (this.Form.invalid || this.errorNumero) {
      this.error = true;
      this.loading = false;
      this.fg.mensaje('Falta información', 'Formulario incompleto.', 'error');
    } else {
      this.error = false;
      const nom = this.Form.controls[this.nombre].value.toString();
      const tel = this.Form.controls[this.telefono].value.toString();
      const ema = this.Form.controls[this.email].value.toString();
      const dir = this.Form.controls[this.direccion].value.toString();
      const body = `<b>Nombre:</b> ${nom}<br><b>Telefono:</b> ${tel}<br><b>Correo:</b> ${ema}<br><b>Dirección:</b> ${dir}`;

      // const resp = await this.fg.sendEmail('Cita', body);

      const data = new FormData();
      data.append('asunto', 'Consulta');
      data.append('body', body);
      await this.es.sendEmail(data).subscribe(res => {
        if (res) {
          this.modalService.dismissAll();
          this.limpiar();
          this.fg.mensaje('Exito!', 'Se envio mensaje con exito.', 'success');
        }
      }, (err => {
        this.fg.mensaje('Uups, Algo salio mal.', 'Estamos tarabajando para resolver el problema', 'error');
        this.loading = false;

      }));

    }
  }

  validaNumero() {
    const tel = this.Form.controls[this.telefono].value.toString();
    if (tel.length < 5) {
      this.errorNumero = true;
    } else {
      this.errorNumero = false;
    }
  }

  validaCheck() {
    this.checkSelect = !this.checkSelect;
  }

  valida(event: string) {
    const resp = (this.Form.controls[event].invalid && this.Form.controls[event].touched) ? true : false;
    return resp;
  }

  validarEmail() {
    return this.Form.controls[this.email].invalid && !this.Form.controls[this.email].pristine;
  }

  cargarInformacion() {
    this.informacion.push(
      {
        icono: '../../../assets/iconos/icono_formas-de-pago.svg',
        titulo: 'Facilidades de pago',
        informacion: 'Manejamos excelentes planes de financiación, pensando en usted y la comodidad de su tratamiento.'
      },
      {
        icono: '../../../assets/iconos/icono_tecnologia-avanzada.svg',
        titulo: 'Tecnología avanzada',
        informacion: 'Contamos con alta tecnología en nuestras instalaciones, para garantizarle una atención segura y oportuna.'
      },
      {
        icono: '../../../assets/iconos/icono_odontologos-especializados.svg',
        titulo: 'Odontólogos especialistas',
        informacion: 'Somos un equipo de especialistas que cumple con los más estrictos estándares de calidad, para ofrecerle una experiencia única y resultados excepcionales.'
      }
    );
  }

  limpiar() {
    this.error = false;
    this.loading = false;
    this.errorNumero = false;
    this.errorEmail = false;
    this.checkSelect = false;
    this.Form.reset();
  }

}
