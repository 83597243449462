<header appScroll> 
  <div class="barraNavegacion" py-3 >

    <div class="contactoD">

      <div class="top-line-left">
        <div class="top-info direccion">Carrera 12 # 8 - 89, Ocaña N.S.</div>
        <div class="top-info numero">Llámanos: <a href="tel:+575626574">(+57) 5626574.</a></div>
      </div>

      <div class="top-line-right">
        <div class="top-info">
          <ul class="top-social">
            <li>
              <a href="https://www.facebook.com/syeodontologica" target="_blank" class="social-facebook">
                <fa-icon [icon]="faFacebookSquare"></fa-icon>
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/saludyestetica.co/" target="_blank" class="social-instagram">
                <fa-icon [icon]="faInstagramSquare"></fa-icon>
              </a>
            </li>

            <li>
              <a href="https://wa.me/573153818593" target="_blank" class="social-whapsApp">
                <fa-icon [icon]="faWhatsappSquare"></fa-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-light nav">
      

      <div class="container-fluid todo">

        <div class="logoBotones">
          <a class="navbar-brand logo" [routerLink]="[ '/home' ]"><img src="../../../assets/images/LOGO.svg"
              alt="Logo"></a>

          <div class="botones">
            <button class="navbar-toggler botonNav" type="button" data-toggle="collapse"
              data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
              aria-label="Toggle navigation" (click)="cambiarEstado(1)">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>

        <div class="contenido">

          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="elementNav">
              <a data-toggle="collapse" data-target="#navbarNavAltMarkup" [routerLink]="[ '/tratamientos' ]" routerLinkActive="activeP">
                Tratamientos
              </a>
              <a data-toggle="collapse" data-target="#navbarNavAltMarkup" [routerLink]="[ '/quienesSomos' ]" routerLinkActive="activeP">
                Quiénes somos
              </a>
              <a data-toggle="collapse" data-target="#navbarNavAltMarkup" [routerLink]="[ '/profesionales' ]" routerLinkActive="activeP">
                Profesionales
              </a>
              <a data-toggle="collapse" data-target="#navbarNavAltMarkup" [routerLink]="[ '/contactanos' ]" routerLinkActive="activeP">
                Contáctanos
              </a>
            </div>
          </div>
          
        </div>
      </div>
    </nav>
  </div>
</header>