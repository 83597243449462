import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StrapiService } from 'src/app/Services/strapi.service';
@Component({
  selector: 'app-slider-profesionales',
  templateUrl: './slider-profesionales.component.html',
  styleUrls: ['./slider-profesionales.component.scss']
})
export class SliderProfesionalesComponent implements OnInit {
  images = [0, 1, 2, 3, 4, 5].map((n) => `../../../assets/profesionales/fotos/${n}.jpg`);
  contenido : any = [];
  // Datos Personales para modal
  fotoPersonal: any = '';
  nombrePersonal: any = '';
  tituloPersonal: any = '';
  universidadTituloPersonal: any = '';
  especializacionPersonal: any = '';
  universidadEspPersonal: any = '';
  diplomadosPersonal: any = '';

  constructor(private modalService: NgbModal, private strapiService: StrapiService) { }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 5000,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      400: {
        items: 1,
        nav: false,
      },
      740: {
        items: 2,
        margin: 20,
        nav: false
      },
      940: {
        items: 3,
        margin: 20,
        nav: false
      }
    }
  };

  ngOnInit(): void {
    this.cargarInformacion();
  }

  async cargarInformacion() {
    const data = await this.strapiService.getData('profesionales');

    if(data) {
      for (let i = 0; i < data.length; i++) {
        const el = data[i];
        const prof = el.attributes;
        const img = el.attributes.img.data?.attributes.url;
        this.contenido.push( {
          profesion: prof.profesion,
          univerProfesion: prof.univerProfesion,
          especializacion: prof.especializacion,
          universidadEspe: prof.universidadEspe,
          diplomados: prof.diplomados ? prof.diplomados.replace(/\n/g, "<br />") : '',
          nombre: prof.nombre,
          img: img
        } )
      }
    } else {
      this.contenido.push(
        {
          profesion: 'Odontóloga General',
          univerProfesion: 'Universidad Santo Tomas',
          especializacion: 'Especialista en Ortodoncia',
          universidadEspe: 'Universidad Cooperativa de Colombia',
          diplomados: `-Filosofía Swing. <br />
          -Mecanismo de finalización en Ortodoncia. <br />
          -Frenck System Protocolos de Ortodoncia. <br />
          -Congresos Sociedad de Colombia de Ortodoncia. <br />
          -Técnica de Auto ligado Damon System. <br />
          -Filosofía Meaw y Medicina de la Oclusión.`,
          nombre: 'DRA. ELIANA MERCEDES CLARO ÁLVAREZ',
          img: this.images[0]
        },
        {
          profesion: 'Odontólogo General',
          univerProfesion: 'Universidad Santo Tomas',
          especializacion: 'Especialista en Periodoncia y Óseo integración',
          universidadEspe: 'Universidad Cooperativa de Colombia-Bogotá',
          diplomados: `-Regeneración Ósea Vertical y Horizontal. <br />
          -Seminarios anuales Sociedad Colombiana de Periodoncia. <br />
          -Cirugía plástica periodontal. <br />
          -The Emergency Implant with Hahn tapared, Course. <br />
          -Tratamientos Protésicos sobre implantes con procera. <br />
          -Workshop Fotografía clínica producto &amp; Marketing en Odontología.`,
          nombre: 'DR. CARLOS ALBERTO CÁCERES GONZÁLEZ',
          img: this.images[1]
        },
        {
          profesion: 'Odontóloga General',
          univerProfesion: 'Universidad Santo Tomas',
          especializacion: 'Diplomado en Estética',
          universidadEspe: 'A.N.D.E.S.',
          diplomados: '',
          nombre: 'DRA. MÓNICA PATRICIA CLARO ÁLVAREZ',
          img: this.images[2]
        },
        {
          profesion: 'Odontóloga General',
          univerProfesion: 'Universidad Cooperativa de Colombia Medellín',
          especializacion: 'Diplomado en Estética',
          universidadEspe: 'Natural Smile Center',
          diplomados: '',
          nombre: 'DRA. PAULA ANDREA ALVAREZ PEÑARANDA',
          img: this.images[3]
        },
        {
          profesion: 'Odontóloga General',
          univerProfesion: 'Universidad Santo Tomas',
          especializacion: 'Especialista en Rehabilitación Oral',
          universidadEspe: 'Universidad militar CIEO',
          diplomados: '',
          nombre: 'DRA. YELITZA VICTORIA PEÑARANDA DELGADO',
          img: this.images[4]
        },
        {
          profesion: 'Odontóloga General',
          univerProfesion: 'Universidad Santo Tomas',
          especializacion: 'Especialista en Endodoncia',
          universidadEspe: 'Universidad Santo Tomas',
          diplomados: '',
          nombre: 'DRA. FRANCY NATHALY MANRIQUE DÍAZ',
          img: this.images[5]
        }
      );
    }
  }

  limpiar() {
    this.fotoPersonal = '';
    this.nombrePersonal = '';
    this.tituloPersonal = '';
    this.universidadTituloPersonal = '';
    this.especializacionPersonal = '';
    this.universidadEspPersonal = '';
    this.diplomadosPersonal = '';
  }

  abrirModal(modalVentana: any, doctor: any) {
    this.limpiar();
    this.modalService.open(modalVentana, { centered: true, size: 'xl' });
    this.cargarDatosModal(doctor);
  }

  cargarDatosModal(doctorIndividual: any) {
    this.fotoPersonal = doctorIndividual.img;
    this.nombrePersonal = doctorIndividual.nombre;
    this.tituloPersonal = doctorIndividual.profesion;
    this.universidadTituloPersonal = doctorIndividual.univerProfesion;
    this.especializacionPersonal = doctorIndividual.especializacion;
    this.universidadEspPersonal = doctorIndividual.universidadEspe;
    this.diplomadosPersonal = doctorIndividual.diplomados;
  }
}
