import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-t-accordion',
  templateUrl: './t-accordion.component.html',
  styleUrls: ['./t-accordion.component.scss'],
  animations: [
    trigger('changeState', [
    state ('stateOpen', style({width: '105%'})),
    state ('stateClose', style({width: '100%'})),
    state ('stateView', style({})),
    state ('stateNoView', style({opacity: 0, display:'none'})),
    transition('stateClose=>stateOpen', [animate('0.3s ease-in')]),
    transition('stateOpen=>stateClose', [animate('0.3s ease-out')]),
    transition('stateNoView<=>stateView', [animate('1s ease-in-out')]),
    // transition('stateView=>stateNoView', [animate('0.4s ease-out')])
    ])
  ]
})
export class TAccordionComponent implements OnInit {
  data: any = [];
  constructor() {}

  imagen: any = '';
  nombre: any = '';
  titulo1: any = '';
  content1: any = '';
  titulo2: any = '';
  content2: any = '';
  contenido: any = '';

  // toState = 'stateClose';
  toStateContent1 = 'stateView';
  toStateContent2 = 'stateNoView';

  ngOnInit(): void {
    this.cargarInfo();
  }

  cargarInfo() {
    this.data = [
      {
        parentName: 'ODONTOLOGIA GENERAL',
        titulo1: '',
        titulo2: '',
        content1:
          'Es la encargada de diagnosticar y tratar los problemas dentales, devolviéndole la estética y función a los dientes para lograr una armonía en la sonrisa.',
        content2: '',
        select: true,
        img: '../../../../assets/tratamientos/odontologiaGeneral.png',
        toState: 'stateOpen',
      },
      {
        parentName: 'ODONTOLOGIA ESTÉTICA',
        titulo1: 'DISEÑO DE SONRISA',
        titulo2: 'ACLARAMIENTO DENTAL',
        content1:
          'Procedimiento que busca embellecer la sonrisa con una apariencia más juvenil y armónica, corrigiendo problemas de tamaño, forma y color de los dientes.',
        content2:
          'Es el tratamiento que logra mejorar  el tono o color de los dientes, dejándolos más blancos y brillantes.',
          select: false,
          img: '../../../../assets/tratamientos/odontologiaEstetica.png',
          toState: 'stateClose',
      },
      {
        parentName: 'ORTODONCIA',
        titulo1: '',
        titulo2: '',
        content1:
          'Especialidad que se encarga de lograr la correcta posición de los dientes, mejorando la armonía de los arcos, la función y la máxima estética dental. \n\nContamos con:',
        content2: '',
        check: [
          {
            contenido:
              'Ortodoncia convencional (Brackets metálicos y estéticos).',
          },
          {
            contenido:
              'Ortodoncia con Brackets de Autoligado (metálicos y estéticos) Brindan baja fricción por el no uso de elásticos, menor tiempo de tratamiento y mayor higiene.',
          },
        ],
        select: false,
        img: '../../../../assets/tratamientos/ortodoncia.png',
        toState: 'stateClose',
      },
      {
        parentName: 'PERIODONCIA',
        titulo1: '',
        titulo2: '',
        content1:
          'Es la especialidad que trata las enfermedades de las encías y del hueso que sostiene los dientes.  Estas enfermedades son la causa más común de la perdida de dientes.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/periodoncia.png',
        toState: 'stateClose',
      },
      {
        parentName: 'ENDODONCIA',
        titulo1: '',
        titulo2: '',
        content1:
          'Es un tratamiento para aliviar el dolor y salvar los dientes cuando han sido afectados por caries profundas, golpes o fracturas. Permitiendo que se conserven por más tiempo en tu boca.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/endodoncia.png',
        toState: 'stateClose',
      },
      {
        parentName: 'IMPLANTOLOGIA',
        titulo1: '',
        titulo2: '',
        content1:
          'Consiste en reemplazar los dientes perdidos mediante la colocación de un aditamento o implante dental en el hueso de los maxilares y recuperar así la pieza dental y su función.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/implantologia.png',
        toState: 'stateClose',
      },
      {
        parentName: 'CIRUGIA ORAL',
        titulo1: '',
        titulo2: '',
        content1:
          'Es la especialidad que trata quirúrgicamente las enfermedades de los dientes, de los maxilares y de los demás tejidos de la boca.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/cirugiaOral.png',
        toState: 'stateClose',
      },
      {
        parentName: 'REHABILITACION ORAL',
        titulo1: '',
        titulo2: '',
        content1:
          'Esta especialidad se encarga de reemplazar los dientes perdidos mediante diferentes tipos de prótesis dentales que devuelven la función y la estética, recuperando la belleza y armonía de la sonrisa.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/rehabilitacionOral.png',
        toState: 'stateClose',
      },
      {
        parentName: 'RADIOLOGIA ORAL',
        titulo1: '',
        titulo2: '',
        content1:
          'Las radiografías  ayudan a diagnosticar las enfermedades y lesiones de los dientes y del hueso de soporte, facilitando así su tratamiento.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/radiologiaOral.png',
        toState: 'stateClose',
      },
      {
        parentName: 'PROMOCIÓN Y PREVENCIÓN ORAL',
        titulo1: '',
        titulo2: '',
        content1:
          'Comprende las actividades encaminadas a prevenir los problemas de salud oral que incluyen la educación y procedimientos preventivos como los sellantes, aplicación de flúor y profilaxis dental.',
        content2: '',
        select: false,
        img: '../../../../assets/tratamientos/promocionP.png',
        toState: 'stateClose',
      },
    ];

    this.imagen = this.data[0].img;
    this.nombre = this.data[0].parentName;
    this.titulo1 = this.data[0].titulo1;
    this.content1 = this.data[0].content1;
    this.titulo2 = this.data[0].titulo2;
    this.content2 = this.data[0].content2;
    this.contenido = this.data[0].check;
  }

  toggleAccordian(event: { target: any }, index: string | number) {
    const element = event.target;
    element.classList.toggle('active');
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }

  viewInfo(tratamiento: any) {
    this.data.forEach((element: any) => {
      if (element.parentName === tratamiento.parentName) {
        element.select = true;
        element.toState = 'stateOpen';
      } else {
        element.select = false;
        element.toState = 'stateClose';
      }
    });

    if (this.toStateContent1 === 'stateView') {
      this.toStateContent1 = 'stateNoView';
      this.toStateContent2 = 'stateView';
    } else {
      this.toStateContent1 = 'stateView';
      this.toStateContent2 = 'stateNoView';
    }

    setTimeout(() => {
      this.imagen = tratamiento.img;
      this.nombre = tratamiento.parentName;
      this.titulo1 = tratamiento.titulo1;
      this.content1 = tratamiento.content1;
      this.titulo2 = tratamiento.titulo2;
      this.content2 = tratamiento.content2;
      this.contenido = tratamiento.check;
    }, 950);
  }
}
